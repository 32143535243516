<template>
<div>

  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-card-header class="mb-3">
      <b-row>
        <b-col> <i class="icon-menu mr-1"></i>Booking Details Report</b-col>
      </b-row>
    </b-card-header>
    <b-card>
      <div class="row">
        <b-col sm="3">
          <b-form-group>
            <label for="previous_dates">Previous Dates</label>
            <b-form-select
              required
              v-model="bookingDetailsData.selectedpreviousDates"
              id="previous_dates"
              :plain="true"
              text-field="Cinema"
              value-field="CinemaID"
              :options="previousDates"
              @change="getFromToDate"
            >
              <template slot="first">
                <option :value="'0'" disabled>
                  -- Select Previous Dates --
                </option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="3">
          <b-form-group>
            <label for="from-date">From Date :</label>
            <b-form-input
              type="date"
              v-model="bookingDetailsData.FromDate"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col sm="3">
          <b-form-group>
            <label for="to-date">To Date :</label>
            <b-form-input
              type="date"
              v-model="bookingDetailsData.ToDate"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="cinema_name">Select Cinema Name :</label>
            <!-- <v-select
              :reduce="(AllCinemas) => AllCinemas.CinemaID"
              label="CinemaName"
              placeholder="Select Cinema"
              v-model="bookingDetailsData.selectedCinema"
              :options="$store.getters['common/getAllCinemas']"
              @change="getScreen"
            /> -->
            <b-form-select
              v-model="bookingDetailsData.selectedCinema"
              id="cinemas"
              :plain="true"
              text-field="CinemaName"
              value-field="CinemaID"
              :options="$store.getters['common/getAllCinemas']"
              @change="getScreen"
            >
              <template slot="first">
                <option :value="'0'" disabled>-- Select Cinema --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="screen_name">Screen :</label>
            <b-form-select
              v-model="bookingDetailsData.selectedScreen"
              id="screen_name"
              :plain="true"
              text-field="ScreenName"
              value-field="ScreenID"
              :options="ScreenList"
              @change="getMovie"
            >
              {{ ScreenList }}
              <template slot="first">
                <option :value="'0'" disabled>-- Select Screen --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="movie_name">Movie :</label>
            <b-form-select
              required
              v-model="bookingDetailsData.selectedMovie"
              id="movie_name"
              :plain="true"
              text-field="Title"
              value-field="ID"
              :options="MovieList"
              @change="getShowTime"
            >
              <template slot="first">
                <option :value="'0'" disabled>-- Select Movie --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="show_time">Show Time :</label>
            <b-form-select
              required
              v-model="bookingDetailsData.selectedShowTime"
              id="show_time"
              :plain="true"
              text-field="Cinema"
              value-field="CinemaID"
              :options="showTimeList"
            >
              <template slot="first">
                <option :value="'0'" disabled>-- Select Show Time --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <label>Status :</label>
          <b-row>
            <b-form-radio
              v-model="bookingDetailsData.selectedStatus"
              name="status_option"
              value="Session Expired"
              class="mx-1"
              >Session Expired</b-form-radio
            >
            <b-form-radio
              v-model="bookingDetailsData.selectedStatus"
              name="status_option"
              value="Completed"
              class="mx-1"
              checked
              >Completed</b-form-radio
            >
            <b-form-radio
              v-model="bookingDetailsData.selectedStatus"
              name="status_option"
              value="All"
              >All</b-form-radio
            >
          </b-row>
        </b-col>
      </div>
      <div class="row">
        <b-col sm="12" class="text-center">
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click="getBookingDetailsReport"
            >Search</b-button
          >
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click.prevent="resetForm"
            >Reset</b-button
          >
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click="ExportBookingExcel"
            >Export to Excel</b-button
          >
        </b-col>
      </div>
    </b-card>
    <hr />
    <b-card>
        <div style="width: 100%;height: 100%;position: absolute;background: white;z-index: 1000;" v-if="isLoader"><CircleLoader  />
    </div>
      <v-client-table
        :data="data1"
        :columns="columns1"
        :options="options1"
      ></v-client-table>
      <hr />
      <v-client-table
        class="my-2"
        :data="data2"
        :columns="columns2"
        :options="options2"
      ></v-client-table>
    </b-card>
  </div>
  
</div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import AccountReportService from "@/services/AccountReportService";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import CircleLoader from "../../SchedulesDetails/Circle.vue";

// jspdf-autotable
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName, WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";

export default {
  name: "BookingDetailsReport",
  components: {
    Event,
    cSwitch,
    // Loading,
    CircleLoader,
  },
  watch: {},
  data: function () {
    return {
      isLoader:false,
      cinemaList: [],
      bookingDetailsData: {
        selectedpreviousDates: "",
        FromDate: "",
        ToDate: "",
        selectedCinema: 0,
        selectedScreen: 0,
        selectedMovie: 0,
        selectedShowTime: "",
        selectedStatus: "Completed",
      },
      previousDates: ["Last One Month", "Last Two Months", "Last Three Months"],
      MovieList: [],
      ScreenList: [],
      showTimeList: [],

      //Transaction Failures
      columns1: ["CinemaName", "NoofTicket", "TicketPrice", "TransactionCount"],
      data1: [],
      options1: {
        headings: {
          CinemaName: "Mall",
          NoofTicket: "Total Tickets",
          TicketPrice: "Total Amount(BHD)",
          TransactionCount: "Total Transaction",
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      //Transaction Failures
      //Book New Transcation
      columns2: [
        "Sr",
        "BookingID",
        "OrderID",
        "Theatre",
        "ScreenName",
        "MovieName",
        "ShowDate",
        "CustName",
        "UserEmail",
        "Prefix",
        "UserMobile",
        "PaymentMode",
        "PaymentStatus",
        "TransactionDate",
        "TimeSpentonPG",
        "BookStatus",
        "SeatNames",
        "NoOfTickets",
        "TotalAmount",
        "Source",
      ],
      data2: [],
      finalArr: [],
      ExcelArr: [],
      options2: {
        headings: {
          Sr: "SN.",
          BookingID: "Booking ID",
          OrderID: "Order ID",
          Theatre: "Mall",
          ScreenName: "Screen",
          MovieName: "Movie Name",
          ShowDate: "Show Date",
          CustName: "Customer Name",
          UserEmail: "User Email",
          Prefix: "Prefix",
          UserMobile: "User Mobile",
          PaymentMode: "Payment Mode",
          PaymentStatus: "Transaction Result",
          TransactionDate: "Transaction Date",
          TimeSpentonPG: "Time Spent in Payment Gateway", //PEnding
          BookStatus: "Booking Result",
          SeatNames: "Seat Names",
          NoOfTickets: "No Of Tickets",
          TotalAmount: "Total Amount",
          Source: "Source",
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      //Book New Transcation

      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  beforeMount() {
    this.$store.dispatch("common/setAllCinemas");
    this.getFromToDate();
  },
  mounted() {
    this.getBookingDetailsReport();
  },
  methods: {
    getFromToDate() {
      var today = new Date();
      var firstDay = "";
      var lastDay = "";
      if (this.bookingDetailsData.selectedpreviousDates == "") {
        // firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );
        lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      } else if (
        this.bookingDetailsData.selectedpreviousDates == "Last One Month"
      ) {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate()
        );
        lastDay = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );
      } else if (
        this.bookingDetailsData.selectedpreviousDates == "Last Two Months"
      ) {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 2,
          today.getDate()
        );
        lastDay = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );
      } else if (
        this.bookingDetailsData.selectedpreviousDates == "Last Three Months"
      ) {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 3,
          today.getDate()
        );
        lastDay = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );
      }
      var firstDay_dd = String(firstDay.getDate()).padStart(2, "0");
      var firstDay_mm = String(firstDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var firstDay_yyyy = firstDay.getFullYear();

      var lastDay_dd = String(lastDay.getDate()).padStart(2, "0");
      var lastDay_mm = String(lastDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var lastDay_yyyy = lastDay.getFullYear();

      firstDay = firstDay_yyyy + "-" + firstDay_mm + "-" + firstDay_dd; //2018-06-01
      lastDay = lastDay_yyyy + "-" + lastDay_mm + "-" + lastDay_dd; //2018-06-01

      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = dd + "-" + mm + "-" + yyyy; //2018-06-01
      this.FromDate = today;
      this.bookingDetailsData.FromDate = firstDay;
      if (this.bookingDetailsData.selectedpreviousDates != "") {
        this.bookingDetailsData.ToDate = lastDay;
      } else {
        this.bookingDetailsData.ToDate = firstDay;
      }
    },
    getScreen() {
      if (this.bookingDetailsData.selectedCinema) {
        let payload = {
          CinemaID: this.bookingDetailsData.selectedCinema,
        };
        this.ScreenList = [];
        AccountReportService.getScreensByCinemaID(payload)
          .then((response) => {
            const { data } = response;
            console.log(response);
            if (
              data.Status &&
              (data.ScreenList.length > 0 || data.ScreenList.length > 0)
            ) {
              this.ScreenList = data.ScreenList;
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch((error) => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    getMovie() {
      if (this.bookingDetailsData.selectedScreen) {
        let payload = {
          ScreenID: this.bookingDetailsData.selectedScreen,
        };
        this.MovieList = [];
        AccountReportService.getMoviesByScreenID(payload)
          .then((response) => {
            const { data } = response;
            if (
              data.Status &&
              (data.MovieList.length > 0 || data.MovieList.length > 0)
            ) {
              this.MovieList = data.MovieList;
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch((error) => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    getShowTime() {
      if (this.bookingDetailsData.selectedMovie) {
        let payload = {
          CountryID: 113,
          CinemaID: this.bookingDetailsData.selectedCinema,
          ScreenID: this.bookingDetailsData.selectedScreen,
          MovieID: this.bookingDetailsData.selectedMovie,
          FromDate: this.bookingDetailsData.FromDate,
          ToDate: this.bookingDetailsData.ToDate,
        };
        this.showTimeList = [];
        AccountReportService.getShowTimeByMovieID(payload)
          .then((response) => {
            const { data } = response;
            if (
              data.Status &&
              (data.TimeList.length > 0 || data.TimeList.length > 0)
            ) {
              this.showTimeList = data.TimeList;
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch((error) => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    ExportBookingExcel() {
      if (this.ExcelArr.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.ExcelArr);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "BOOKING_DETAILS");
        let ExcelDate = new Date().toDateString();
        if (this.bookingDetailsData.FromDate) {
          ExcelDate = new Date(this.bookingDetailsData.FromDate).toDateString();
        } else {
          ExcelDate = new Date().toDateString();
        }
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_BOOKING_DETAILS_Report_${ExcelDate}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    getBookingDetailsReport() {
      this.isLoader=true;
      this.data1 = [];
      this.data2 = [];
      this.finalArr = [];
      this.ExcelArr = [];
      let payload = {
        selectedpreviousDates: this.bookingDetailsData.selectedpreviousDates,
        FromDate: this.bookingDetailsData.FromDate,
        ToDate: this.bookingDetailsData.ToDate,
        CinemaID: this.bookingDetailsData.selectedCinema,
        ScreenID: this.bookingDetailsData.selectedScreen,
        MovieID: this.bookingDetailsData.selectedMovie,
        ShowTime: this.bookingDetailsData.selectedShowTime,
        StatusType: this.bookingDetailsData.selectedStatus,
        CountryID: 113,
      };

      AccountReportService.getBookingDetailsReport(payload)
        .then((response) => {
          const { data } = response;
          if (
            data.Status &&
            (data.TransReportsDetails.CinemaWiseData.length > 0 ||
              data.TransReportsDetails.Data.length > 0)
          ) {
            this.data1 = data.TransReportsDetails.CinemaWiseData;
            this.data2 = data.TransReportsDetails.Data;
            if (this.data2.length > 0) {
              let finalArr = this.finalArr;
              let NewArr = this.data2.map((x) => {
                finalArr.push({
                  "SN.": x["Sr"],
                  "Booking ID": x["BookingID"],
                  "Order ID": x["OrderID"],
                  Mall: x["Theatre"],
                  Screen: x["ScreenName"],
                  "Movie Name": x["MovieName"],
                  "Show Date": x["ShowDate"],
                  "Customer Name": x["CustName"],
                  "User Email": x["UserEmail"],
                  Prefix: x["Prefix"],
                  "User Mobile": x["UserMobile"],
                  "Payment Mode": x["PaymentMode"],
                  "Transaction Result": x["PaymentStatus"],
                  "Transaction Date": x["TransactionDate"],
                  "Time Spent in Payment Gateway": x["TimeSpentonPG"],
                  "Booking Result": x["BookStatus"],
                  "Seat Names": x["SeatNames"],
                  "No Of Tickets": x["NoOfTickets"],
                  "Total Amount": x["TotalAmount"],
                  Source: x["Source"],
                });
              });
              this.ExcelArr = finalArr;
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
           this.isLoader=false;
        })
        .catch((error) => {
          console.log("Catch on AddUser-getRoles Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm() {
      Object.keys(this.bookingDetailsData).forEach(
        (key) => (this.bookingDetailsData[key] = "")
      );
      this.bookingDetailsData["selectedStatus"] = "Completed";
      this.getFromToDate();
      this.bookingDetailsData["selectedCinema"] = 0;
      this.bookingDetailsData["selectedScreen"] = 0;
      this.bookingDetailsData["selectedMovie"] = 0;
      this.bookingDetailsData["selectedShowTime"] = "";
    },
    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style>
label {
  font-weight: 600;
}
</style>
